import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
// import { Fragment } from 'react';
// import Home from './components/Home'
// import {About} from './components/About'
// import {Contact} from './components/Contact'
// import {NoMatch} from './components/NoMatch'
// import {Layout} from './components/Layout'
// import {NavigationBar} from './components/NavigationBar'
// import {Jumbo} from './components/Jumbo'
// import AlertBox from './components/AlertBox'

import {Home, Contact, NoMatch, NavigationBar
    , TopNavigationBar, Footer, MainEvents, EventView} from '.'
import {Container} from 'react-bootstrap'

import {connect} from 'react-redux'


const pageStyle = {
    backgroundColor: '#f8f8f6',
    // fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"
  }

const mainContainerStyle = {
    paddingTop: '10px'

}

class MainApp extends React.Component {
    
  

  render() {

    return (<React.Fragment>
        <div style ={{...pageStyle,direction:this.props.direction, textAlign: this.props.textAlign}}>
        <Router>
        <TopNavigationBar />
        <Container style = {mainContainerStyle}>
            
            <NavigationBar />
            {/* <Container style = {{backgroundColor:'#f1f1f1', padding:'0px'}} > */}
            
            {/* <Jumbo /> */}
            {/* <AlertBox /> */}
            {/* <Layout> */}
                
                <Switch>
                    <Route exact path="/" component = {Home} />
                    <Route path="/events" component = {MainEvents} />
                    <Route path="/contact" component = {Contact} />
                    <Route path="/event/:id" component={EventView} />
                    <Route   component = {NoMatch} />
                </Switch>
               
            {/* </Layout> */}
            {/* </Container> */}
            <Footer />
        </Container>
        </Router>
        </div>
    </React.Fragment>
    )
  }
}



const mapStateToProps = (state) => {
    
    const newState = {
        ...state,
        lang: state.appLang.lang,
        direction: state.appLang.direction,
        textAlign: state.appLang.textAlign
    }
    return newState
 }

export default connect(mapStateToProps)(MainApp)