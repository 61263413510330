import React from 'react'
import {Card, Row, Col} from 'react-bootstrap'




export class  NewsCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            linkHover: false
        }
    }
    
    render(){
    return (
        <Card style = {{transition: '.5s all ease'}} className = {'mt-3 rounded-0 ' + (this.state.linkHover === true ? 'shadow-lg':'shadow-sm')} onMouseEnter = {() => {this.setState({linkHover:true})}} onMouseLeave = {() => {this.setState({linkHover:false})}}>
            
            <Row >
                <Col sm={12} md ={4} >
                    <Card.Img variant="top" className="rounded-0"
                        // src="https://www.visitportugal.com/sites/www.visitportugal.com/files/mediateca/23_660x371.jpg"
                        src = {this.props.img}
                    />
                </Col>
                <Col sm={12} md ={8} >
                    
                <Card.Body>
                    <Card.Title >{this.props.title}</Card.Title>
                    <Card.Text className="text-secondary w">{this.props.text}</Card.Text>
                </Card.Body>

                </Col>
            </Row>
        </Card>
      

    )
    }
}
