import React from 'react'
import {Navbar, Nav, Row,Button, Container} from 'react-bootstrap'

import {useDispatch, useSelector} from 'react-redux'
import {setAppLanguage} from '../actions/testActions'
import {Link} from 'react-router-dom'


export const TopNavigationBar = () => {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.appLang.lang)

  return (
  <Navbar bg="white" expand="lg">
    <Container>
    
    <Navbar.Brand as={Link} to="/">
      <img
        src="/lognew.png"
        width="130"
        height="50"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
    <Navbar.Brand as={Link} to="/">DLV-AFRIKA</Navbar.Brand>
    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        
      </Nav>
      
    </Navbar.Collapse>
    <Row className="justify-content-end">
      
      <Button variant="link" style = {{textDecoration: 'none', color: lang==='ar'?'black':'#a0a0a0', fontWeight: lang==='ar'?'bold':'normal'}} className={"px-1 pt-0  " + (lang === "ar"?" disabled text-black":" text-secondary") } onClick = { () => dispatch(setAppLanguage({lang:"ar",direction:"rtl", textAlign:"right"}))} >AR</Button>
      <Button variant="link" style = {{textDecoration: 'none', color: lang==='de'?'black':'#a0a0a0', fontWeight: lang==='de'?'bold':'normal'}} className={"px-1 pt-0  " + (lang === "de"?" disabled text-black":" text-secondary") } onClick = { () => dispatch(setAppLanguage({lang:"de",direction:"ltr", textAlign:"left"}))}>DE</Button>
    </Row>
    
    </Container>
  </Navbar>
)
  }