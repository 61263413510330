import React from 'react'
import {Carousel, Row,Col} from 'react-bootstrap'
import {connect} from 'react-redux'

class  CarouselSlider extends React.Component {

  render(){
    return (
      <Carousel className = 'pl-0 pr-0' >
        {
          this.props.slides.map((item, index) => {
            return (
          <Carousel.Item key ={index}>
            <Row >
              <Col md={4} className="pl-5 pr-5 pt-4 bg-dark text-white d-none d-md-block">
              {/* <Col md={4} className={"pl-5 pr-5 pt-4 bg-dark " + item['text_color'] + " d-none d-md-block"}> */}
                  <h3>{item[this.props.lang + '_caption_title']}</h3>
                  <p>{item[this.props.lang + '_caption_text']}</p>
              </Col>
              <Col md={8} sm = {12} className = "pl-0 pr-0">
                  <img
                  className="d-block w-100"
                  src={item.img}
                  alt="First slide"
                  />
                  <Carousel.Caption>
                  <h3 style={{color: item['text_color']}}>{item[this.props.lang + '_side_title']}</h3>
                  <p style={{color: item['text_color']}}>{item[this.props.lang + '_side_text']}</p>
                  </Carousel.Caption>
              </Col>
            </Row>
          
          </Carousel.Item>
          )})
      }
  
</Carousel>
    )
  }
}


const mapStateToProps = (state) => {
    
  return {
      slides: state.slides.items,
      lang: state.appLang.lang
  }
  
}

export default connect(mapStateToProps)(CarouselSlider)