import React from 'react'

import {Container} from 'react-bootstrap'

// import Buttoms from './buttoms'
import {NewsCard, CarouselSlider} from './'
import {connect} from 'react-redux'



class Home extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    
      render() {
        return(
              <React.Fragment>
              
              <CarouselSlider />
              <Container style = {{backgroundColor:'#ffffff', padding:'0px'}}>
                {this.props.news.map((item, index) => <NewsCard key={index} img = {item.img} title = {item[this.props.lang + '_title']} text = {item[this.props.lang + '_text']} />)}
              </Container>
            </React.Fragment>
          
        )
      }
    }

    const mapStateToProps = (state) => {
    
      return {
          news: state.news.items,
          lang: state.appLang.lang
      }
      
   }
  
   export default connect(mapStateToProps)(Home)