import React from 'react';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

// import { Fragment } from 'react';
// import Home from './components/Home'
// import {About} from './components/About'
// import {Contact} from './components/Contact'
// import {NoMatch} from './components/NoMatch'
// import {Layout} from './components/Layout'
// import {NavigationBar} from './components/NavigationBar'
// import {Jumbo} from './components/Jumbo'
// import AlertBox from './components/AlertBox'

// import {Home, About, Contact, NoMatch, Layout, NavigationBar, Jumbo, AlertBox, TopNavigationBar} from './components'
import {MainPage} from './components'

import './App.css';

// library.add(fab, faCheckSquare, faCoffee)
export default class App extends React.Component {

  

  render() {
    return <React.Fragment>
     
      <MainPage />
    </React.Fragment>
  }
}
