import {LOAD_LANG_ITEMS_CONFIG} from '../actions/types'

const initialState = {
    de :{
        NavigationBar:{
            home: 'START',
            dlvAfrika: 'DLV-Afrika',
            current: 'Aktuell',
            importantLinks: 'Wichtig Link'
        }
    },
    ar :{
        NavigationBar:{
            home: 'الصفحة الرئيسية',
            dlvAfrika: 'DLV-Afrika',
            current: 'الحالي',
            importantLinks: 'روابط هامة'
        }
    }

}

export function langItemsConfig(state = initialState, action){
    switch(action.type){
        case LOAD_LANG_ITEMS_CONFIG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}