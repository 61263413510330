import {SET_APP_LANG} from '../actions/types'

const initialState = {
    lang: 'de',
    direction: 'ltr',
    textAlign: 'left'
}

export function appLanguageReducer(state = initialState, action){
    switch(action.type){
        case SET_APP_LANG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}