import {LOAD_NEWS} from '../actions/types'

const initialState = {
    items:[
        {
            id: 1,
            img:"/Home.png",
            de_title:"TITEL 1",
            de_text:"Nordwestprovinz und Limpopo und Mpumalanga im Norden bilden das Safari-Kernland Südafrikas. In Greater Kruger, einem Blockbuster eines Reiseziels, gibt es unzählige fahrerfreundliche Nationalparks zu erkunden.",
            ar_title:"عنوان ١",
            ar_text:"من الأحداث أن أطوار الحياة في أفريقيا خلال هذه الحقبة زمنية لم يتم دراستها دراسة وافية. تعدّ الطبقات المنتمية إلى أوائل الحقبة .."
        },
        {
            id: 2,
            img:"https://saiia.org.za/wp-content/uploads/2016/09/EricBauer_Flickr_16015901826_80219a114f_k-wpv_1200x700_center_center.jpg",
            de_title:"TITEL 2",
            de_text:"Nordwestprovinz und Limpopo und Mpumalanga im Norden bilden das Safari-Kernland Südafrikas. In Greater Kruger, einem Blockbuster eines Reiseziels, gibt es unzählige fahrerfreundliche Nationalparks zu erkunden.",
            ar_title:"عنوان ١",
            ar_text:"من الأحداث أن أطوار الحياة في أفريقيا خلال هذه الحقبة زمنية لم يتم دراستها دراسة وافية. تعدّ الطبقات المنتمية إلى أوائل الحقبة .."
        },
        {
            id: 3,
            img:"https://storage.needpix.com/rsynced_images/elephant-3770687_1280.jpg",
            de_title:"TITEL 1",
            de_text:"Nordwestprovinz und Limpopo und Mpumalanga im Norden bilden das Safari-Kernland Südafrikas. In Greater Kruger, einem Blockbuster eines Reiseziels, gibt es unzählige fahrerfreundliche Nationalparks zu erkunden.",
            ar_title:"عنوان ١",
            ar_text:"من الأحداث أن أطوار الحياة في أفريقيا خلال هذه الحقبة زمنية لم يتم دراستها دراسة وافية. تعدّ الطبقات المنتمية إلى أوائل الحقبة .."
        },
        {
            id: 4,
            img:"https://www.tripsavvy.com/thmb/w347cnBJM-fpkcenGYL5Dprywyw=/950x0/filters:no_upscale():max_bytes(150000):strip_icc()/elephant-watering-hole-86010971-5c7946cb46e0fb00011bf2c9.jpg",
            de_title:"TITEL 1",
            de_text:"Nordwestprovinz und Limpopo und Mpumalanga im Norden bilden das Safari-Kernland Südafrikas. In Greater Kruger, einem Blockbuster eines Reiseziels, gibt es unzählige fahrerfreundliche Nationalparks zu erkunden.",
            ar_title:"عنوان ١",
            ar_text:"من الأحداث أن أطوار الحياة في أفريقيا خلال هذه الحقبة زمنية لم يتم دراستها دراسة وافية. تعدّ الطبقات المنتمية إلى أوائل الحقبة .."
        },
        {
            id: 5,
            img:"https://aviationcablenet.com/wp-content/uploads/2018/06/botswana.jpg",
            de_title:"TITEL 1",
            de_text:"Nordwestprovinz und Limpopo und Mpumalanga im Norden bilden das Safari-Kernland Südafrikas. In Greater Kruger, einem Blockbuster eines Reiseziels, gibt es unzählige fahrerfreundliche Nationalparks zu erkunden.",
            ar_title:"عنوان ١",
            ar_text:"من الأحداث أن أطوار الحياة في أفريقيا خلال هذه الحقبة زمنية لم يتم دراستها دراسة وافية. تعدّ الطبقات المنتمية إلى أوائل الحقبة .."
        }
    ]
}

export function newsItems(state = initialState, action){
    switch(action.type){
        case LOAD_NEWS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}