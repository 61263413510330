import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import ListLinkHover from './ListLinkHover'
import {SocialLinkHover} from './SocialLinkHover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faTwitter, faInstagram, faGooglePlus} from '@fortawesome/free-brands-svg-icons'




export const Footer = () => {
    
    return (
        <Container className = "bg-dark text-white mt-3 mb-3 " >
            
        <Row>
            <Col style={{height:'120px'}} >
                <h4>Group 1</h4>
                <ul className="list-unstyled quick-links">
                <ListLinkHover>Start</ListLinkHover>
                <ListLinkHover>Events</ListLinkHover>
                <ListLinkHover>History</ListLinkHover>
                <ListLinkHover>Contact Us</ListLinkHover>
                    {/* <li><FontAwesomeIcon icon="coffee" />hello</li>}
                    <li><FontAwesomeIcon icon={['fab', 'apple']} />hello</li> */}
                </ul>
            </Col>

            <Col>
                <h4>Group 2</h4>
                <ul className="list-unstyled quick-links">
                <ListLinkHover>Start</ListLinkHover>
                <ListLinkHover>Events</ListLinkHover>
                    {/* <li><FontAwesomeIcon icon="coffee" />hello</li>}
                    <li><FontAwesomeIcon icon={['fab', 'apple']} />hello</li> */}
                </ul>
            </Col>

            <Col>
            <h4>Group 3</h4>
                <ul className="list-unstyled quick-links">
                <ListLinkHover>Start</ListLinkHover>
                <ListLinkHover>Events</ListLinkHover>
                    {/* <li><FontAwesomeIcon icon="coffee" />hello</li>}
                    <li><FontAwesomeIcon icon={['fab', 'apple']} />hello</li> */}
                </ul>
            </Col>

        </Row>
        <Row className="justify-content-xs-center" style={{height:'60px'}}>
            <Col>
        <ul className="list-unstyled list-inline social text-center ">
            <SocialLinkHover><FontAwesomeIcon icon={faFacebook} className="bg-dark text-white"/></SocialLinkHover>
            <SocialLinkHover><FontAwesomeIcon icon={faTwitter} className="bg-dark text-white"/></SocialLinkHover>
            <SocialLinkHover><FontAwesomeIcon icon={faInstagram} className="bg-dark text-white"/></SocialLinkHover>
            <SocialLinkHover><FontAwesomeIcon icon={faGooglePlus} className="bg-dark text-white"/></SocialLinkHover>
            <SocialLinkHover><FontAwesomeIcon icon={faEnvelope} className="bg-dark text-white"/></SocialLinkHover>
        </ul>
        </Col>
        </Row>

        <Row className="justify-content-xs-center ">
            <Col>
            {/* <p className="text-center"><u><a href="https://www.nationaltransaction.com/">National Transaction Corporation</a></u> is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]</p> */}
            <p className="h6 text-center">All right Reversed.<a className="text-green ml-2" href="https://www.softpuzzles.com" target="_blank">SoftPuzzles</a></p>
            </Col>
        </Row>
        
        </Container>
		
    )
}
