import React from 'react'
import {Table, Spinner} from 'react-bootstrap'
import {connect} from 'react-redux'
import {fetchUsers} from '../actions/testActions'

class UsersTable extends React.Component{
    componentDidMount(){
        this.props.fetchUsers()
    }


    render(){
        return this.props.loading ? <Spinner animation="border" variant="primary" />: (
            <Table striped bordered hover variant="dark">
            
            <thead>
                <tr>
                <th>#</th>
                <th>Name</th>
                <th>username</th>
        <th>Email</th>
                </tr>
            </thead>
            <tbody>
                {
                    (this.props.users && this.props.users.length > 0) &&
                    (
                        this.props.users.map(user => {
                            return (
                                <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.name}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                </tr>
                            )
                        })
                    )
                    
                }
            </tbody>
            </Table>
        )
    }
}

// const mapStateToProps = state => ( {
//     users : state.users
// })

const mapStateToProps = (state) => {
    const newState = {
        ...state,
        users: state.users.users,
        loading: state.users.loading
    }
    return newState
    //  return {
    //     users : state.users
    //  }
 }

// Map dispatch to props
const mapDispatchToProps = (dispatch) => {
    return {
      // This dispatch will trigger 
      // the Ajax request we setup
      // in our actions
      fetchUsers: () => dispatch(fetchUsers())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)