import {SET_MEGA_MENU_DISPLAY} from '../actions/types'

const intialState = {
    menus:[
        {
            name:'dlv-afrika',
            display: 'none'
        },
        {
            name:'menu2',
            display: 'none'
        }
    ]
    // menus:[{
    //     display: 'none',
    //     name:''
    // }]
}

export function megaMenuDisplay(state = intialState, action){
    switch(action.type){
        case SET_MEGA_MENU_DISPLAY:
            return {
                ...state,
                menus: state.menus.map(item => item.name === action.menuDisplay.name ? {...item, display: action.menuDisplay.display}: item) 
            }
        default:
            return state
    }
}