import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleDoubleRight, faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons'
import {connect} from 'react-redux'



const linkStyleEnter = {
    padding: '2px',
	marginLeft: '3px',
    fontWeight:'700',
    transition: '.5s all ease',
    marginTop:'-4px'
    // textTransform: 'uppercase',
}

const linkStyleLeave = {
    padding: '0px',
	marginLeft: '0px',
    fontWeight:'500',
    transition: '.5s all ease',
    // textTransform: 'uppercase'
}


class ListLinkHover extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            linkHover: false
        }
    }

    render(){
    return  <li style = {this.state.linkHover ? linkStyleEnter: linkStyleLeave} onMouseEnter = {() => {this.setState({linkHover:true})}} onMouseLeave = {() => {this.setState({linkHover:false})}}><FontAwesomeIcon icon={ this.props.direction === 'ltr'? faAngleDoubleRight : faAngleDoubleLeft} /> {this.props.children}</li>
    }

}


const mapStateToProps = (state) => {
    
    return {
        direction: state.appLang.direction
    }
    
 }

 export default connect(mapStateToProps)(ListLinkHover)