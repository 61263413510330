export const TEST_ACTION_1 = 'TEST_ACTION_1';
export const TEST_ACTION_2 = 'TEST_ACTION_2';
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_BOOKS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const SET_MEGA_MENU_DISPLAY = "SET_MEGA_MENU_DISPLAY";

export const SET_APP_LANG = 'SET_APP_LANG'
export const LOAD_LANG_ITEMS_CONFIG = 'LOAD_LANG_ITEMS_CONFIG'

export const LOAD_NEWS = 'LOAD_NEWS'
export const LOAD_SLIDS = 'LOAD_SLIDS'
export const LOAD_EVENTS = 'LOAD_EVENTS'