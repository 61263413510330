import React from 'react'

import {Container, Row, Col, Image, Button} from 'react-bootstrap'
import {connect} from 'react-redux'



class EventView extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    
      render() {
        return(
              <React.Fragment>
              
              
              <Container style = {{backgroundColor:'#ffffff', padding:'0px'}}>
                <Row>
                    <Col xs={12}>
                        <Image src={this.props.event.img} fluid = 'false' />
                    </Col>
                    <Col xs={12}>
                        <h3>{this.props.event[this.props.lang + '_title']}</h3>
                        {this.props.event[this.props.lang + '_text']}
                    </Col>
                    <Col>
                    <Button>Archive</Button>
                    </Col>
                </Row>
              </Container>
            </React.Fragment>
          
        )
      }
    }

    

    const mapStateToProps = (state, ownProps) => {
        const {lang} = state.appLang
        const { id } = ownProps.match.params
        const event = state.events.items.find( item => item.id == id)
        return { lang, event }     
   }
  
   export default connect(mapStateToProps)(EventView)