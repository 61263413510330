import React from 'react'
import {Row,Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {setMegaMenuDisplay} from '../actions/testActions'


const dropdownContent = {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#fff',
    // width: '100%',
    left: '0',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    zIndex: '100',
    marginTop: '-1px',
    minWidth: '100%', 
    paddingTop:'20px',
    paddingBottom:'20px',
    paddingLeft:'10px',
    paddingRigth:'10px'
  }
const topmenu = {
    display: 'none',
    position: 'relative',
    backgroundColor: '#fff',
    // width: '100%',
    top:'-24px',
    left: '0px',
    marginBottom: '-24px',
    boxShadow: '0 -8px 8px rgba(0,0,0,0.2)',
    zIndex: '101'
}

export const MegaMenu = (props) => {
    const dispatch = useDispatch()
    const menuDispaly = useSelector(state => state.megaMenuDisplay.menus.find(item => item.name === props.megaName).display)
    const displayStyle = {...dropdownContent, display: menuDispaly}
    const displayTopMenu = {...topmenu, display: menuDispaly}
    return (
        <React.Fragment>
        
    <div style = {displayStyle}>
        <Row>
            <Col md={3} className="d-none d-md-block">
                <h3><Link style={{textDecoration: 'none'}} to = {props.linkName} onClick = {() => dispatch(setMegaMenuDisplay({name:props.megaName, display:'none'}))}>{props.menuName}</Link></h3>
            </Col>
            <Col md={9}>
            <Row>
                {props.links.map((item, index) => {
                    return (
                    <Col lg={4} md={6} sm={12} key = {index}>
                        <Link style={{textDecoration: 'none'}} to = {item.address} onClick = {() => dispatch(setMegaMenuDisplay({name:props.megaName, display:'none'}))}>{item.linkName}</Link>
                    </Col>
                    )
                })}
            </Row>
            </Col>

        </Row>
        
        
    </div>
    <div style={displayTopMenu} >
        <Link style={{textDecoration: 'none'}} to = {props.linkName} onClick = {() => dispatch(setMegaMenuDisplay({name:props.megaName, display:'none'}))}>{props.menuName}</Link>
        </div>
    </React.Fragment>
)}