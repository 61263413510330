import {TEST_ACTION_1} from '../actions/types'

const intialState = {
    text: ''
}

export function action1Reducer(state = intialState, action){
    switch(action.type){
        case TEST_ACTION_1:
            return {
                ...state,
                text: action.text
            }
        default:
            return state
    }
}