import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {setMegaMenuDisplay} from '../actions/testActions'
import {Link} from 'react-router-dom'

import {MegaMenu} from './'

export const NavigationBar = (props) => {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.appLang.lang)
  const langItemConfig = useSelector(state => state.langItemConfig[lang].NavigationBar)  
  const eventsLinks = useSelector(state => state.events.items).map(event => ({linkName: event[lang + '_title'], address: '/event/' + event.id}))
  
  return (
  <Navbar bg="transparent" expand="md" style={{fontFamily: 'Titillium Web'}}>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav >
      <Nav.Link as={Link} to="/">{langItemConfig.home}</Nav.Link>
      <Nav.Link as={Link} to="/">{langItemConfig.dlvAfrika}</Nav.Link>
      
      <Nav.Item className="nav-link" onMouseEnter = {() => dispatch(setMegaMenuDisplay({name:'dlv-afrika', display:'block'}))} onMouseLeave = {() => dispatch(setMegaMenuDisplay({name:'dlv-afrika', display:'none'}))}>{langItemConfig.current}
      <MegaMenu megaName = 'dlv-afrika' linkName = "/events" menuName = {langItemConfig.current} links = {eventsLinks}/>
      </Nav.Item>
      <Nav.Link as={Link} to="/contact">{langItemConfig.importantLinks}</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
)}

