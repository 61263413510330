import React from 'react'
import {Alert} from 'react-bootstrap'
import {connect} from 'react-redux'
// import { testAction2 } from '../actions/testActions'


class AlertBox extends React.Component {
    render() {
        return (
            <Alert variant = 'primary'>
                Hi this is alert {this.props.action2.text}
                {/* <Alert.Link href="#">an example link</Alert.Link>. Give it a click if you */}
            </Alert>
        )
    }
}

// Object of action creators
// const mapDispatchToProps = {
//     action2: action2
//   }

const mapStateToProps = state => ( {
    action2 : state.action2
})
  
export default connect(mapStateToProps)(AlertBox);
