import React from 'react'
import {Jumbotron, Container} from 'react-bootstrap'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

const Styles = styled.div`
    .jumbo {
        background: url(https://backgroundcheckall.com/wp-content/uploads/2017/12/jumbotron-background-image-2.jpg) no-repeat fixed bottom;
        background-size: cover;
        height: 200px;
        position: relative;
        z-index: -2;
    }
`

export const Jumbo = () => {
    const action1 = useSelector(state => state.action1)
    // console.log(action1)
    return (
        <Styles>
            <Jumbotron className="jumbo">
                <Container>
                    <h1>Welcome</h1>
                    <p>Learn react bootstarap {action1.text}</p>
                </Container>
            </Jumbotron>
        </Styles>
    )
}
