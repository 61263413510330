import {TEST_ACTION_1, TEST_ACTION_2, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE
  , SET_MEGA_MENU_DISPLAY, SET_APP_LANG, LOAD_LANG_ITEMS_CONFIG, LOAD_NEWS, LOAD_SLIDS, LOAD_EVENTS} from './types'
import axios from 'axios'

export const testAction1 = (text) => {
    return {
        type: TEST_ACTION_1,
        text
    }
}

export const testAction2 = (text) => {
    return {
        type: TEST_ACTION_2,
        text
    }
}

export const setMegaMenuDisplay = (menuDisplay) => {
  return {
      type: SET_MEGA_MENU_DISPLAY,
      menuDisplay
  }
}

export const fetchUsersRequest = () => {
    return {
      type: FETCH_USERS_REQUEST
    }
  }
  
  export const fetchUsersSuccess = users => {
    return {
      type: FETCH_USERS_SUCCESS,
      payload: users
    }
  }
  
  export const fetchUsersFailure = error => {
    return {
      type: FETCH_USERS_FAILURE,
      payload: error
    }
  }

  export const setAppLanguage = payload => {
    return {
        type: SET_APP_LANG,
        payload
    }
}

export const loadLangItemsConfig = payload => {
  return {
    type: LOAD_LANG_ITEMS_CONFIG,
    payload
  }

}

export const loadNews = payload => {
  return {
    type: LOAD_NEWS,
    payload
  }
}

export const loadSlides = payload => {
  return {
    type: LOAD_SLIDS,
    payload
  }
}


export const loadEvents = payload => {
  return {
    type: LOAD_EVENTS,
    payload
  }
}



  export const fetchUsers = () => {
    return (dispatch) => {
      dispatch(fetchUsersRequest())
      axios
        .get('https://jsonplaceholder.typicode.com/users')
        .then(response => {
          // response.data is the users
          const users = response.data
          dispatch(fetchUsersSuccess(users))
        })
        .catch(error => {
          // error.message is the error message
          dispatch(fetchUsersFailure(error.message))
        })
    }
  }


// it is in flux
// export const testAction1WithDispatch = (text) => {
//     const action =  {
//         type: TEST_ACTION_1,
//         text
//     }
//     dispatch(action)
// }

// export const testAction2WithDispatch = (text) => {
//     const action = {
//         type: TEST_ACTION_2,
//         text
//     }
//     dispatch(action)
// }