import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'


const linkStyleEnter = {
    padding: '3px 0',
    marginRight: '5px',
    fontSize:'30px',
    transition: '.5s all ease',
    marginTop:'-10px'
    // textTransform: 'uppercase',
}

const linkStyleLeave = {
    padding: '3px 0',
    marginRight: '15px',
    fontSize:'25px',
    transition: '.5s all ease',
    // textTransform: 'uppercase'
}


export class SocialLinkHover extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            linkHover: false
        }
    }

    render(){
    return (     
            <li className="list-inline-item" style = {this.state.linkHover ? linkStyleEnter: linkStyleLeave} onMouseEnter = {() => {this.setState({linkHover:true})}} onMouseLeave = {() => {this.setState({linkHover:false})}}><a href="">{this.props.children}</a></li>
    )
    }

}
