import React from 'react'

import {Card, Container, Row, Col} from 'react-bootstrap'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'



class MainEvents extends React.Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
    
      render() {
        return(
              <React.Fragment>
                <Container style = {{backgroundColor:'#ffffff', padding:'0px'}}>
                  <Row className="flex-wrap flex-row ">
              {
          this.props.events.map((item, index) => {
            return (
              <Col key = {index} lg={4} sm={12} className="d-flex mb-2">
              <Card as={Link} to = {"/event/" + item.id}  className="rounded-0 shadow-sm">
                <Card.Img variant="top" src={item.img} />
                <Card.Body>
                  <Card.Title>{item[this.props.lang + '_title']}</Card.Title>
                  <Card.Text>{item[this.props.lang + '_text']}</Card.Text>
                </Card.Body>
              </Card>
              </Col>
              
            )})}

              </Row>
                </Container>
            </React.Fragment>
          
        )
      }
    }

    const mapStateToProps = (state) => {
    
      return {
          events: state.events.items,
          lang: state.appLang.lang
      }
      
   }
  
   export default connect(mapStateToProps)(MainEvents)