import {combineReducers} from 'redux'
import {action1Reducer } from './action1Reducer'
import {action2Reducer } from './action2Reducer'
import {megaMenuDisplay } from './megaMenuDisplay'
import userReducer from './userReducer'
import {appLanguageReducer} from './appLanguage'
import {langItemsConfig} from './langItemsConfig'
import {newsItems} from './newsItems'
import {eventsItems} from './eventsItems'
import {slidesItems} from './slidesItems'

const rootReducer =  combineReducers ({
    action1: action1Reducer,
    action2: action2Reducer,
    users: userReducer,
    megaMenuDisplay: megaMenuDisplay,
    appLang: appLanguageReducer,
    langItemConfig: langItemsConfig,
    news: newsItems,
    events: eventsItems,
    slides: slidesItems

})

export default rootReducer